@import 'src/assets/styles/colors';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  min-height: calc(100vh - 50px);
}

.AppSidebar {
  min-height: 100%;
  width: 170px;
  background: #3b3b3b;
  z-index: 10;
}

.AppContent {
  background-color: white;
  min-height: 100vh;
  width: 100%;
}

.AppWithBanner .AppContent,
.AppWithBanner .AppSidebar {
  min-height: calc(100vh - 50px);
}
