@import 'src/assets/styles/colors';

.DateFieldInput {
  border: none !important;
  padding: 15px 15px !important;
  font-size: 14px !important;
  outline: none;
  color: #7783a1;
  border-radius: inherit;
  position: relative !important;
}