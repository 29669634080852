@import 'src/assets/styles/colors';

.FileField {
  min-height: 50px;
}

.FileFieldButton {
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  right: 0;
  height: 100%;
  border: none;
  background: none;
  border-left: 1px solid #dae1ed;
  align-items: center;
  font-size: 15px;
  color: #7783a1;
  outline: none;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
  &:hover {
    background: rgba(244, 244, 244, 0.8);
  }
}

.FileFieldPlaceholder {
  width: calc(100% - 130px);
  min-height: 50px;
  display: flex;
  align-items: center;
  color: #d3d3d3;
  font-weight: 600;
}

.FileFieldPlaceholderSelected {
  color: #070707;
}

.FileFieldInput {
  border: none;
  padding: 0px 10px;
  font-size: 14px;
  width: 100%;
  outline: none;
  color: #7783a1;
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

.FileFieldInputIcon {
  padding-right: 0;
}

.FileFieldInput::placeholder{
  color: #9c9c9c;
}

.FileFieldInputError {
  border: 1px solid $color-red !important;
}

.FileFieldInputDisabled {
  background: rgba(100, 100, 100, 0.07);
  cursor: not-allowed;
}

.FileFieldFileName {
  margin: 0 0 0 0px;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FileFieldFileSize {
  padding: 0 0 0 10px;
    font-weight: 400;
    font-size:12px;
}