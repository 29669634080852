.helpertext {
    font-style: italic;
    font-size: 11px;
}
.searchInput {
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.pqlist {
    list-style-type: disc;
    padding-left: 1rem;
    max-height: 80%;
    overflow-y: scroll;
}
.listitem {
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    padding-left: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}
.listitem:hover {
    background-color: rgb(210, 210, 210);
}
.tableholder{
    padding: 3rem;
}
.pqahint {
    font-style: italic;
    font-size: 11px;
}
.pqinput{
    border-radius: 2px;
    border-color: rgb(203, 203, 203);
    cursor: pointer;
    border-style: dashed;
}
.clearbutton {
    border-radius: 0;
    border: 0;
    width: 3rem;
    height: 1.7rem;
    background: transparent;
    color: white;
    cursor: pointer;
}
.clearbutton svg {
    fill: rgb(0, 0, 0); 
 }
.clearbutton:hover svg {
   fill: rgb(147, 147, 147); 
}
.thxbutton{
    background-color: #42c19a;
    border-color: transparent;
    border-radius: 4px; 
    color: white;
    display: flex;
    align-items: center;
    border: none;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    outline: none;
    background: #42c19a;
    background-color: rgb(66, 193, 154);
    color: #fff;
    border-radius: 4px;
    padding: 0 60px;
    height: 40px;
    margin: 0 15px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    transition: all .3s;
    margin-left: auto;
}
.btnholder {
    display: flex;
    flex-direction: row;
    align-items: center;
}