@import 'src/assets/styles/colors';

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}


.RotateLoaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.RotateLoaderBg {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.RotateLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  border: 2px solid $color-primary-dark;
  border-bottom-color: transparent;
  animation-iteration-count: 3;
  animation-fill-mode: both;
  display: inline-block;
  transition: all 0.3s;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 100%;
  background: transparent !important;
  animation: rotate-loader 1.25s 0s linear infinite;
}

.RotateLoaderRelative {
  position: relative;
  top: initial;
  left: initial;
  margin: 0 !important;
}

