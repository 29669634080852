.IconButton {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.IconButton:hover {
  opacity: 0.8;
}
