@import 'src/assets/styles/colors';

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.Button {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: $color-primary-dark;
  color: white;
  border-radius: 4px;
  padding: 0px 30px;
  height: 40px;
  margin: 0px 15px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.ButtonSmall .ButtonIcon {
  margin-right: 5px;
}

.ButtonUltraSmall .ButtonIcon {
  margin-right: 5px;
}

.ButtonLoader {
  width: 0;
  animation-iteration-count: 3;
  height: 0;
  margin: 0;
  animation-fill-mode: both;
  display: inline-block;
  transition: all 0.3s;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 100%;
  animation-duration: 1s;
  background: transparent !important;
  animation: rotate 0.75s 0s linear infinite;
}

.ButtonLoaderActive{
  width: 13px;
  height: 13px;
  margin-left: 5px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
}

.ButtonLoaderPrimary{
  border-color: $color-primary !important;
  // border-color: white !important;
  border-bottom-color: transparent !important;
}

.white {
  color: #5e5e5e;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    &:hover:not(:disabled) {
      background-color: rgba($color-white, 0.7);
    }
}

.yellow{
  background: $color-yellow;
    &:hover:not(:disabled) {
      background-color: rgba($color-yellow, 0.7);
    }
}

.blue {
  background: $color-blue;
  &:hover:not(:disabled) {
    background-color: rgba($color-blue, 0.7);
  }
}


.red {
background: $color-red;
    &:hover:not(:disabled) {
      background-color: rgba($color-red, 0.7);
    }
}

.primary {
  background-color: $color-primary-dark;
  border-color: transparent;
  &:hover {
    &:hover:not(:disabled) {
      background-color: rgba($color-primary2, 0.7);
    }
  }
}

.default {
  color: #7783a1;
  background-color: $color-white;
  border-color: #dae1ed;
  border-style: solid;
  border-width: 1px;
  &:hover:not(:disabled) {
    background-color: rgba($color-medium-grey, 0.1);
  }
}

.ButtonFullwidth {
  width: 100%;
}

.ButtonUppercase{
  text-transform: uppercase;
}

.ButtonSmall {
  padding: 5px 14px 5px 14px;
  height: 30px;
  font-size: 12px;
}

.ButtonUltraSmall {
  padding: 3px 8px 3px 8px;
  font-size: 11px;
  height: auto;
  margin: 0 5px;
  display: inline-block;
}

.ButtonUltraSmall div {
  display: none;
}

.ButtonNoMargin {
  margin: 0 !important;
}

.ButtonIcon{
  margin-right: 10px;
  margin-left: 0;
}

.CancelButton:hover {
  background-color: red !important;
  color: white !important
}
