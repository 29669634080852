@import 'src/assets/styles/colors';

.Pagination {
  // width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 36px 0;
  display: flex;
  align-items: center;
}

.PaginationPage {
  border: 1px solid #f1f4f8;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  background-color: $color-white;
  cursor: pointer;
  outline: none;
}

.PaginationPage:last-child{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.PaginationPage:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.PaginationPageActive,
.PaginationPage:hover:not(.PaginationPageDisabled) {
  background-color: $color-primary-dark;
  color: $color-white;
  svg {
    path {
      fill: white !important;
    }
  }
}

.PaginationPrevious {
  transform: rotate(180deg);
}

.PaginationPrevious, 
.PaginationConinue {
  border: none !important;
  cursor: pointer;
  outline: none;
  width: 40px;
  height: 40px;
  &:hover {
    svg {
      path {
        fill: $color-primary-dark !important;
      }
    }
  }
}

.PaginationPerPage{
  margin-left: auto;
}

svg {
  transition: all 0.3s;
}