.left {
  vertical-align: middle;
}
.index {
  width: 20px;
}
.right {
  vertical-align: middle;
  font-size: 15px;
  color: grey;
}
.comments {
  font-size: 15px;
  color: grey;
  width: 400px;
}
.commentsInput {
  width: 350px;
}
.addBucket {
  text-align: right !important;
  button {
    margin: 0;
    display: inline-block;
    div {
      display: none;
    }
  }
}
.table {
  min-width: 50%;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  white-space: nowrap;
  background-color: white;
  margin-bottom: 24px;

  td,
  th {
    text-align: center;
    padding: 8px;
  }

  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  thead th {
    font-size: 10px;
    font-weight: 300;
    border-bottom: 1px solid #d9d9d9;
  }

  thead th:nth-child(odd) {
  }

  tr:nth-child(even) {
    background: #f8f8f8;
  }

  tfoot tr:nth-child(even) {
    background: transparent;
  }
  tfoot td {
    text-align: right;
    border-right: none;
  }

}
