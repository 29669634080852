$color-primary: #29fabd;
$color-primary2: #00ab91;
$color-primary-dark: #42c19a;

$color-orange: #f99e44;
$color-yellow: #f9c665;
$color-light-red: #f13a55;
$color-red: #cb354b;
$color-grey: #f2f2f2;
$color-light-blue: #5acff9;
$color-blue: #69baed;
$color-dark-blue: #78a4e2;
$color-light-violet: #8790d5;
$color-violet: #a465be;
$color-dark-violet: #b67fa5;
// App Colors
$color-medium-grey: #9c9c9c;
$color-white: white;

$color-text: #7783a1;
$color-text-light: #a9b5c7;
$color-border: #dae1ed;
$color-white: #fff;
