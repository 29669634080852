@import 'src/assets/styles/colors';

.dataTitle {
}
.dataContent {
}
.content {
  padding: 0 60px;
}
.subHeader {
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
}

.answersContent {
  border-top: 1px solid #dce1e8;
}
.answersLeftCol {
  padding-top: 25px;
  min-height: 50vh;
  border-right: 1px solid #dce1e8;
}
.answersRightCol {
  position: sticky;
  top: 50px;
  padding-top: 25px;
}
.answersTitle {
  color: #35404a;
  margin-top: 0;
  font-size: 17px;
}
.answerName {
  color: #35404a;
  font-size: 24px;
}
.answerObject {
  margin-bottom: 30px;
}
.answerPlaceholder {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  font-size: 17px;
  font-weight: 100;
  svg {
    height: 100px;
    fill: $color-primary-dark;
  }
}
