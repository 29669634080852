.FormSection {
  position: relative;
  padding: 0 63px;
  width: 100%;
}

.FormSectionBlocked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
