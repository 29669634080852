@import 'src/assets/styles/colors';

.CheckboxField {
  margin-bottom: 25px;
  margin-right: 10px;
  display: block;
  cursor: pointer;
}

.CheckboxFieldLight .CheckboxFieldBox {
  background-color: white;
  border: 1px solid #dae1ed;
}

.CheckboxFieldDisabled {
  cursor: not-allowed;
}

.CheckboxFieldDisabled input {
  cursor: not-allowed;
}

.CheckboxFieldBox {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #4a4a4a;
  vertical-align: middle;
  transition: all 0.2s;
  color: black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0);
}

.CheckboxFieldBoxActive {
  background-color: #51dbb3;
  color: rgba(0, 0, 0, 1);
}

.CheckboxFieldInput{
  display: none;
}

.CheckboxFieldLabel {
  font-size: 13px;
  color: #ababab;
  margin-left: 12px;
  display: inline-block;
  vertical-align: middle;
}