@import 'src/assets/styles/colors';

.AutocompleteReactSelect {
  margin-bottom: 26px;
}

.AutocompleteReactSelect .ARS__single-value{
  color: $color-text;
}

.AutocompleteReactSelect .ARS__placeholder {
  color: $color-text;
}

.AutocompleteReactSelect .ARS__value-container {
  padding: 11px 15px !important;
}

.AutocompleteReactSelectInputError {
  .ARS__control {
    border: 1px solid $color-red !important;
    border-color: $color-red !important;
  }
}

.AutocompleteReactSelect .ARS__control {
  border-color: $color-border !important;
}
