.svgIcon {
  width: 25px;
  height: 25px;
  margin: 0 5px;
}
.icons {
  display: flex;
}

.ListHeading {
  
  min-width: 200px;
  margin-right: 40px; 
}

.tableLink {
  display: block;
  text-decoration: none;
  display: block;
  color: black;
}