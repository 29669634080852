@import 'src/assets/styles/colors';

.CountrySelector {
    position: relative;
    background-color: #5a5a5a;
    //background-color: #3b3b3b;
    border-radius: 6px;
}

.LanguageSelector {
  display: flex;
  flex-direction: column;
  //row-gap: 5px;
  align-items: flex-start;
  margin: 10px 2px 10px 20px;
  padding: 0px 0px 5px 0px;
  list-style: none;
  transition: all 0.3s ease-out;
}

.OtherOptions {
  overflow-y: scroll;
  margin-top: 10px;
  // Customize scrollbar
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #adb5bd;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
  }
}

.Bar {
    width: 150px;
}

.Selector {
  margin: 0 2px;
  display: flex;
  align-items: center;
  cursor: pointer;

  button {
    display: flex;
    margin: 0;
    padding: 0;
    border: 3px solid transparent;
    background: transparent;
    cursor: pointer;
    opacity: 0.5;

    svg {
      width: 23px;
      height: 14px;
    }
  }

  &:hover, &--selected {
    button {
      opacity: 1;
    }
  }

  &--selected {
    button {
      border-color: rgba(255, 255, 255, 0.6);
    }
  }
}

.Flag {
  height: 16px;
}

.FlagName {
    margin: 10px 7px;
    font-size: 14px;
    color: $color-grey;
}

.ArrowDown {
    position: absolute;
    top: 10px;
    right: 5px;
    transform: rotate(270deg);
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.ArrowUp {
    position: absolute;
    top: 10px;
    right: 5px;
    transform: rotate(90deg);
    cursor: pointer;
    transition: all 0.3s ease-out;
}
