.answerData {
  color: #595959;
  font-size: 14px;
  margin-bottom: 14px; 
}
.answerDataTitle {
  color: #a9b5c7;
  font-size: 12px; 
  display: block;
  margin-bottom: 6px;
  text-transform: uppercase;
}
