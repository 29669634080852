@import 'src/assets/styles/colors';

.Navigation {
  display: flex;
  flex-direction: column;
  position: sticky;
  justify-content: center;
  align-items: center;
  top: 50px;
  width: 170px;
}

.NavigationLogo {
  padding: 15px;
  margin-bottom: 70px;
}

.CountrySelectorBox {
    position: absolute;
    top: 45px;
    z-index: 105;
}
