@import 'src/assets/styles/colors';

.FieldWrapper {
  margin-bottom: 26px;
  width: 100%;
}

.FieldWrapperInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dae1ed;
  border-radius: 4px;
  background-color: #ffffff;
  min-height: 46px;
}

.FieldWrapperIcon {
  width: 25px;
  height: 25px;
  margin-right: 12px;
  margin-left: 12px;
}

.FieldWrapperInputWrapperNoBorder {
  border: none;
}

.FieldWrapperInputWrapperError {
  border: 1px solid $color-red !important;
}

.FieldWrapperInputWrapperDisabled {
  background: rgba(100, 100, 100, 0.07);
}

.FieldWrapperInputWrapper input {
  border-radius: 4px;
  background: transparent;
}

.FieldWrapperLabel {
  font-size: 11px;
  color: #a9b5c7;
  text-transform: uppercase;
  margin-bottom: 11px;
  height: 18px;
  display: flex;
  align-items: center;
}

.FieldWrapperLabelLeft{
  margin-right: 21px;
  color: #7783a1;
  font-weight: 600;
  text-align: right;
  margin-bottom: 0;
}

.FieldWrapperTooltip {
  margin-left: 10px;
  position: relative;
}

.FieldWrapperTooltip:hover .FieldWrapperTooltipContent {
  opacity: 1;
    visibility: visible;
}

.FieldWrapperTooltipContent {

  position: absolute;
  top: -20px;
  left: 100%;
  margin-left: 15px;
  padding: 20px 25px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  // visibility: hidden;
  // opacity: 0;
  transition: all 0.25s;
  border-radius: 4px;
  background-color: #fff;
  z-index: 10;

  &:before {
    content: '';
    clip-path: inset(-5px -5px 0px 0px);
    transform: rotate(-135deg);
    left: -6px;
    top: 22px;
    background: white;
    z-index: 10;
    position: absolute;
    width: 12px;
    height: 12px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
}

.FieldWrapperTooltip:hover svg * {
  fill: $color-primary-dark !important;
}

.FieldWrapperTooltip svg {
  cursor: pointer;
}

.FieldWrapperNoMargin {
  margin-bottom: 0;
}

.FieldWrapperLoader {
  transform: scale(0.7);
  position: absolute;
  right: 10px;
}

.FieldWrapperSuffix {
  padding: 0 20px;
  position: relative;
  background: #f1f4f8;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FieldWrapperLabelLeft {
  display: flex;
  align-items: center;
}

.FieldWrapperIconLeft {

  padding: 0 13px;
  display: flex;
  border-right: 1px solid #707070;
  align-items: center;
  align-self: stretch;
}

.FieldWrapperIconLeft svg {
    width: 21px;
    height: 21px;
}
