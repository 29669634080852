.CampaignFormMediaTitle {
  display: flex;
  align-items: center;
}

.CampaignFormMediaAdd{

  margin-left: 9px;
  outline: none;
  font-size: 20px;
  display: inline-block;
  width: 42px;
  height: 42px;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #17d1af;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
}

.CampaignFormMediaModalScrollable {
  max-height: 65%; 
  min-height: 65%;
  overflow-y: auto;
}