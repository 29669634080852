.overflowWrapper {
  position: relative;
  min-height: 40px;
}
.filtersButton {
  position: absolute;
  right: 0;
}
.wrapper {
  display: flex;
  height: 0;
  flex-direction: column;
  overflow: hidden;
  transition: height 0.12s linear;
}
.wrapperActive {
  height: 180px;
}
.filters {
  display: flex;
}
.ageFilter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ageField {
  max-width: 100px;
  margin-right: 30px;
}
.ageLabel {
  margin-right: 30px;
}
.clear {
  margin-left: auto;
}
