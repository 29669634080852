@import 'src/assets/styles/colors';
.HeaderTitle {
  width: 100%;
  padding: 15px 15px;
  font-family: Open Sans;
  font-size: 34px;
  color: #35404a;
  font-weight: 700;
  border: none;
  outline: none;
}
