@import 'src/assets/styles/colors';

.FieldLabel {
  font-size: 11px;
  color: #a9b5c7;
  text-transform: uppercase;
  margin-bottom: 11px;
  height: 18px;
  display: flex;
  align-items: center;
}

.FieldLabelTooltip {

  margin-left: 10px;
    position: relative;
    text-transform: initial;
}

.FieldLabelTooltip:hover .FieldLabelTooltipContent {
  opacity: 1;
  visibility: visible;
}

.FieldLabelTooltip:hover svg * {
  fill: $color-primary-dark !important;
}

.FieldLabelTooltip svg {
  cursor: pointer;
}

.FieldLabelTooltipContent {
  position: absolute;
  top: -20px;
  left: 100%;
  min-width: 200px;
  margin-left: 15px;
  padding: 10px 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s;
  border-radius: 4px;
  background-color: #fff;
  z-index: 10;

  &:before {
    content: '';
    clip-path: inset(-5px -5px 0px 0px);
    transform: rotate(-135deg);
    left: -6px;
    top: 22px;
    background: white;
    z-index: 10;
    position: absolute;
    width: 12px;
    height: 12px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
}

.FieldLabelNoMargin {
  margin-bottom: 0;
}

.FieldLabelLeft {
  margin-right: 21px;
    color: #7783a1;
    font-weight: 600;
    text-align: right;
    margin-bottom: 0;
}