@import 'src/assets/styles/colors';

.MediaFile {
  display: flex;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  padding: 11px 14px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
}

.MediaFileTrash {
  border: none;
  background: none;
  cursor: pointer;
}

.MediaFileTrash:hover svg * {
  fill: $color-primary;
}

.MediaFileRight{
  margin-left: auto;
}

.MediaFileMedia {
  font-size: 13px;
  color: #7783a1;
}

.MediaFileProgess{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: $color-primary2;
}

.MediaFileFile {
  font-size: 14px;
  color: #35404a;
  font-weight: 600;
  margin-bottom: 4px;
}