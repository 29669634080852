@import 'src/assets/styles/colors';

.Login {
  background: black;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginInputWrapper {
  position: relative;
  }

  .LoginInputWrapper svg,
  .LoginInputWrapper svg * {
    fill: #707070 !important;
  }

  .LoginInputWrapper input {
    color: #707070 !important;
  }

 .LoginInputWrapper .LoginInput{
  background: #424242;
  border-radius: 4px;
  border:none;
 }

.LoginWrapper {
  width: 260px;
}

.LoginHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
}

.LoginHeaderLogo{
  height: 60px;
}

.LoginSubmit {
  width: 100%;
  margin: 70px 0 0;
  text-align: center;
  justify-content: center;
}
