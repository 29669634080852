@import 'src/assets/styles/colors';

.ContentLoader {
  width: 100%;
  min-height: 100vh;
}

.ContentLoaderLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.ContentLoaderContent {
  display: none;
}

.ContentLoaderContentActive {
  display: block;
}

.ContentLoaderError {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;
  text-align: center;
  animation: fadeIn 0.5s;
  animation-fill-mode: both;
}

.ContentLoaderErrorIcon {
  border-radius: 50%;
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: rgba($color-primary2, 0.9);
  transform: scale(0.7);

  &:after {
    left: 30px;
  }

  &:before {
    left: 85px;
  }

  &:after,
  &:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background-color: #414141;
    border-radius: 50%;
    top: 48px;
    animation: emoji1-face-move 5s ease-in-out infinite,
      emoji1-eyes-blink 2s ease-in-out infinite;
    animation-direction: alternate;
  }
}

.ContentLoaderErrorIconMouth {
  position: absolute;
  height: 3px;
  width: 35px;
  background-color: #414141;
  border-radius: 5px;
  top: 68px;
  left: 45px;
  -webkit-animation: emoji1-face-move 5s ease-in-out infinite;
  animation: emoji1-face-move 5s ease-in-out infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.ContentLoaderErrorTitle {
  font-weight: 300;
  color: #464646;
  font-size: 18px;
}

.ContentLoaderErrorDescription{
  margin-top: 10px;
}


@keyframes emoji1-eyes-blink {
  0% {
    height: 10px;
  }
  97% {
    height: 10px;
  }
  100% {
    height: 0px;
  }
}

@keyframes emoji1-face-move {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-20px);
  }
  65% {
    transform: translateX(-20px);
  }
  70% {
    transform: translateX(-10px);
  }
  85% {
    transform: translateX(-10px);
  }
  90% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
