.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 100%;
  overflow-y: scroll;
  position: relative;
  flex: 1;
  width: 100%;
}
.imagesWrapper {
  overflow: hidden;
  position: relative;
  flex: 1;
  width: 100%;
}
.image {
  position: relative;
  width: 19%;
  height: 200px;
  margin-bottom: 20px;
  background-size: cover;
  cursor: pointer;
}
.imageActive {
  border: 3px solid #ea1717;
}
.buttons {
  margin-top: auto;
}
.imageAuthor {
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 3px 10px;
  font-size: 11px;
}

.loadMoreButton {
  width: 100%;
  display: flex;
  justify-content: center;
}
