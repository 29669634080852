@import 'src/assets/styles/colors';

.HeaderTextField {
  width: 100%;
}

.HeaderTextFieldInput {
  border: none;
  padding: 15px 15px;
  font-size: 14px;
  width: 100%;
  font-family: Open Sans;
  font-size: 34px;
  color: #35404a;
  font-weight: 700;
  border: none;
  border-bottom: 1px solid #d5e1eb;
  outline: none;
}

.HeaderTextFieldInputIcon {
  padding-right: 0;
}

.HeaderTextFieldInput::placeholder {
  color: #9c9c9c;
}

.HeaderTextFieldInputDisabled {
  background: rgba(100, 100, 100, 0.07);
  cursor: not-allowed;
}

.HeaderTextFieldInputError {
  border: 1px solid $color-red !important;
}