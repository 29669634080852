@import 'src/assets/styles/colors';

.NavigationSubItem {
  position: relative;
  text-align: left;
  width: 100%;
  display: block;
  padding: 10px 20px;
  color: #898989;
  text-decoration: none;
  font-size: 12px;
  transition: all 0.15s;
}

.NavigationSubItem:hover, .NavigationSubItemActive {
  background: #4f4f4f;
  color: #dadada;
}