.table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  margin-bottom: 24px;

  td,
  th {
    text-align: center;
    padding: 8px;
  }

  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  thead th {
    font-size: 10px;
    font-weight: 300;
    border-bottom: 1px solid #d9d9d9;
  }

  thead th:nth-child(odd) {
  }

  tr:nth-child(even) {
    background: #f8f8f8;
  }
  tfoot td {
    text-align: left;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  button:last-child {
    margin-right: 0;
  }
}

.wrapper {
  position: relative;
}

.bucketError {
  color: red;
}
.bucketGood {
  color: green;
}
.lowerCase {
  text-transform: lowercase;
}

.yellow {
  background: yellow;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
}
