.ManageSurvey {
  padding: 53px 0px;
}
.PexelButton {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  margin-top: 29px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.7;
  }
}

.PexelButton svg {
  height: 48px;
  width: 48px;
}

.SurveyButtons {
  display: flex;
  flex-direction: row;
}

.SurverHeaderStatus {
  padding: 20px 0 0 15px;
}

.CampaignHeaderRight {
  margin-left: auto;
}