.hint {
  font-size: 12px;
  color: #a8a8a8;
  margin-top: -20px;
}
.bodyField {
  position: relative;
  margin-bottom: 50px;
}

.misspell {
  padding-bottom: 6px;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}

.spellCheckContainer {
  width: 220px;
  height: 250px;
  overflow-y: auto;
  padding: 4px;
  border: 1px solid #c3cad5;
  border-image-repeat: 4px;
  position: absolute;
  background: white;
  z-index: 100;
  color: black;
  top: 92px;
  display: flex;
  flex-direction: column;
}

.spellCheckButton {
  position: absolute;
  top: 2px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.noError {
  color: #17d1af;
  padding-top: 10px ;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContent {
  position: absolute;
  top: 150px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.dropdown {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.closeButton {
  margin-top: 20px;
  margin: '10px';
  width: 25%;
  padding: 10px;
  background-color: #CB354B;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.randomizeButton {
  margin-top: 20px;
  margin: '10px';
  width: 25%;
  padding: 10px;
  background-color: #408ECB;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.confirmButton {
  margin-top: 20px;
  margin: '10px';
  width: 25%;
  padding: 10px;
  background-color: #00AA87;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.openModalButton {
  margin-bottom: 16px;
  margin-left: auto;
  padding: 10px 20px;
  background-color: #00AA87;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
