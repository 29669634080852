.table {
  min-width: 100%;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  margin-bottom: 24px;

  td,
  th {
    text-align: center;
    padding: 8px;
  }

  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  thead th {
    font-size: 10px;
    font-weight: 300;
    border-bottom: 1px solid #d9d9d9;
  }

  thead th:nth-child(odd) {
  }

  tr:nth-child(even) {
    background: #f8f8f8;
  }
  tfoot td {
    text-align: right;
  }
}

.lowerCase {
  text-transform: lowercase;
}
