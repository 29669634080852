@import 'src/assets/styles/colors';

.Field {
  border: none;
  padding: 15px 75px 15px 15px;
  font-size: 14px;
  width: 100%;
  outline: none;
  color: $color-text;
  border-radius: inherit;
}

.Selector {
  position: absolute;
  bottom: 0;
  right: 15px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    margin: 0 1px;
    padding: 0;

    button {
      margin: 0;
      padding: 0;
      border: 0;
      color: $color-medium-grey;
      cursor: pointer;
      background: transparent;
      border-bottom: 1px solid transparent;
    }

    &--filled {
      button {
        color: black;
      }
    }

    &--selected {
      button {
        color: $color-primary-dark;
        border-bottom-color: $color-primary-dark;
      }
    }
  }
}

.MaxLengthIndicator {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: grey;
  white-space: nowrap;
  padding: 0 10px;
}
