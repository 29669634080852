@import 'src/assets/styles/colors';
.RadioField {
  margin-bottom: 26px;
  width: 100%;
}

.RadioFieldInput {

  display: flex;
  margin-right: 30px;

  &--small input + label:after {
    left: 4px;
    top: 4px;
    width: 8px;
    height: 8px;
  } 
}

.RadioFieldInputLabel{

  position: relative;
    cursor: pointer;
    padding-left: 32px;

    &:before {
      display: block;
      position: absolute;
      left: 0;
      border-radius: 50%;
      border: 2px solid #7783a1;
      width: 18px;
      height: 18px;
      content: '';
      transition: all 0.15s;
    }
}

.RadioFieldInputLabelSmall {
  font-size: 12px;
  padding-left: 20px;
}

.RadioFieldInputLabelSmall:before {
  width: 16px;
  height: 16px;
}

.RadioFieldInputLabelDisabled{
  cursor: not-allowed;
}

.RadioFieldInputError{
  border: 1px solid $color-red !important;
}

.RadioFieldInput input:checked + label:after {
  transform: scale(1);
}

.RadioFieldInputField {
  display: none;
}

.RadioFieldInput input + label:after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 50%;
  left: 5px;
  top: 5px;
  background: $color-primary-dark;
  width: 8px;
  height: 8px;
  transition: all 0.15s;
  transform: scale(0);
}

.RadioFieldInput input:checked + label:before {
  border: 2px solid $color-primary-dark;
}

.RadioFieldInputWrapper {
  position: relative;
  display: flex;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
}

.RadioFieldInputWrapperError {
  border-bottom: 1px solid $color-red !important;
}

.RadioFieldLabelLeft {
  display: flex;
  align-items: center;
}

.RadioFieldNoMargin {
  margin-bottom: 0;
}

.RadioFieldLabel {
  font-size: 11px;
    color: #a9b5c7;
    text-transform: uppercase;
}

.RadioFieldLabelLeft{
  margin-right: 21px;
      color: #7783a1;
      font-weight: 600;
      text-align: right;
}

.RadioFieldLabelDisabled {
  cursor: not-allowed;
}