.notification {
  background-color: #eee;
  color: #000;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 15px;
  opacity: 1;
  transition: opacity 0.4s;
}
.default {
}
.error {
  background-color: #e57373 !important;
  color: #fff !important;
}
.success {
  background-color: #81c784 !important;
  color: #fff !important;
}
.info {
  background-color: #64b5f6 !important;
  color: #fff !important;
}
.warning {
  background-color: #ffb74d !important;
  color: #fff !important;
}
