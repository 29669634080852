.UserInfoForm {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.UserInfo {
    display: flex;
}

.UserInfoHeader {
    padding: 16px;
    margin: 16px 16px 0px 16px;
    height: 70px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
    border: 1px solid #f6f6f6;
    display: flex;
    align-items: center;
}

.UserInfoHeaderText {
    font-size: 24px;
    font-weight: 500;
    color: black;
    cursor: not-allowed;
    opacity: 0.8;
}

.UserInfoCard {
    border: 1px solid #ececec;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
    padding: 16px;
    margin: 16px;
    overflow-y: scroll;
    height: 80vh;
    width: 315px;

     // Customize scrollbar
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: white;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #adb5bd;
        border-radius: 10px;
    }
}

.UserGiftCard {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
    padding: 16px;
    margin: 16px 16px 16px 0;
    flex: 1;
    overflow-y: scroll;
    height: 80vh;

    // Customize scrollbar
    &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #adb5bd;
        border-radius: 10px;
      }
}

.SubmitButton {
    position: absolute !important;
    top: 32px !important;
    right: 30px !important;
}

.AddPointsButton {
    position: absolute !important;
    top: 32px !important;
    right: 150px !important; 
}

.AddPointsModal {
    position: absolute;
    background-color: white;
    height: 50%;
    width: 25%;
    padding: 20px;
}

.AddPointsInput {
    width: 50% !important;
    left: 25%;
    margin-top: 5% !important;
}

.AddPointsSubmit {
    left: 40%;
    margin-top: 10% !important;
}

.NoGiftcard {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.giftCardLink {
    text-decoration: none;
    color: rgb(38, 38, 222);
}

.BigText {
    font-size: 2.5rem; // Adjust this size as needed
  }
  
.SmallText {
font-size: 1.5rem; // Adjust this size as needed
}