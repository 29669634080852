@import 'src/assets/styles/colors';

.FieldOptions {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  //margin-top: 20px;
  right: -2px;
  border-radius: 4px;
  background-color: #fff;
  z-index: 10;
  max-height: 0px;
  overflow-y: scroll;

  &:before {
    content: '';
    clip-path: inset(-5px -5px 0px 0px);
    transform: rotate(-45deg);
    right: 25px;
    top: -6px;
    background: white;
    z-index: 10;
    position: absolute;
    width: 12px;
    height: 12px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
}

.FieldOptionsOption {
  border: none;
  width: 100%;
  text-align: left;
  padding: 20px 20px;
  background-color: transparent;
  font-size: 14px;
  color: #3d3d3d;
  transition: all 0.2s;
  outline: none;
}

.FieldOptionsOption:not(.FieldOptionsOptionDisabled):hover {
  cursor: pointer;
  color: $color-primary-dark;
}

.FieldOptionsOptionActive {
  cursor: pointer;
  color: $color-primary-dark;
}

.FieldOptionsOptionDisabled {
  color: #c6c6c6;
  cursor: initial;
}


.FieldOptionsShowTop {
  bottom: 100% !important;
  top: initial !important;
  margin-bottom: 0px;
  margin-top: 0 !important;
}

.FieldOptionsActive {
  max-height: 200px;
  opacity: 1;
  visibility: visible;
}
