.AddMediaModalScrollable {
  max-height: 65%; 
  min-height: 65%;
  overflow-y: auto;
}

.AddMediaModalApplyToAllWrapper {
  width: 100%; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5%;
}

.AddMediaModalApplyToAllButton {
  width: 15%; 
  margin: 0; 
  padding: 0; 
  display: flex; 
  justify-content: center; 
  margin-bottom: 2%;
}

.AddMediaModalFieldControl {
  flex: 1; 
  max-width: 15%;
}

.AddMediaModalBottomButtonWrapperLeft {
  justify-content: flex-start;
  width: 50%;
}

.AddMediaModalBottomButtonWrapperRight {
  justify-content: flex-end;
  width: 50%;
  display: flex;
  flex-direction: row;
}

.AddMediaModalBottomButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
  box-sizing: border-box;
}