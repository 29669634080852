.CampaignHeader {
    padding: 31px 36px;
    display: flex;
    background: white;
    position: sticky;
    top: 50px;
    z-index: 15;
    align-items: center;
    border-bottom: 1px solid #d5e1eb;
}
