.CampaignHeader {
  padding: 31px 36px;
  display: flex;
  background: white;
  position: sticky;
  top: 50px;
  // z-index: 15;
  align-items: center;
  border-bottom: 1px solid #d5e1eb;
}

.CampaignHeaderRight {
  margin-left: auto;
}

.CampaignMap {
  height: calc(100vh);
}

.CampaignMapWrapper {
  position: sticky;
  top: 50px;
  height: 400px;
}

.CampaignForm {
  padding: 53px 0px;
}

.CampaignFormSeparator {
  background: #f1f4f8;
  height: 1px;
  width: auto;
  margin: 50px -50px;
}

.CampaignFormContentType {

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CampaignFormContentType .DateField {
width: 100%;
}

.CampaignFormContentTypeSeparator {
  margin: 0 20px 26px;
}

.CampaignFormContentTypeDateField {
  width: 100%;
}

.CampaignFormContentTypeCheckboxField {
  margin-bottom: 10px;
}

.CampaignFormContentTypeError {

  .FieldWrapper__input-wrapper {
    border: none;
  }
}
