.left {
  vertical-align: middle;
  padding-bottom: 15px;
}
.right {
  vertical-align: middle;
  font-size: 15px;
  color: grey;
  padding-bottom: 15px;
}
.checkbox {
  margin-bottom: 0px;
}
