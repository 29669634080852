.EnvBanner {
  width: 100%;
  height: 50px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: fixed;
  z-index: 100;
}

.EnvBannerDev {
  background-color: #00aa87;
}

.EnvBannerTest {
  background-color: #eeee48;
}
