@import 'src/assets/styles/colors';

.NavigationItem {
  position: relative;
  width: 100%;
  height: 50px;
}

.NavigationItemActive .NavigationItemIcon, .NavigationItemActive .NavigationItemIcon svg{
  fill: #17d1af;
  color: #17d1af;
}

.NavigationItemIcon:hover svg *, .NavigationItem:hover .NavigationItemIcon{
  fill: #17d1af !important;
  color: #17d1af;
}

.NavigationItemTooltip {
  
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-10%, -50%);
  margin-left: 0px;
  padding: 10px 10px;
  background: #242424;
  color: white;
  border-radius: 4px;
  transition: all 0.25s;
  opacity: 0;
  visibility: hidden;
  font-size: 12px;
  z-index: 100;
  white-space: nowrap;

  &:before {
    position: absolute;
    content: '';
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 7px 6px 0;
    border-color: transparent #242424 transparent transparent;
  }   
}

.NavigationItem:hover .NavigationItemTooltip {
  transform: translate(15%, -50%);
    opacity: 1;
    visibility: visible;
}


.NavigationItemTooltipWithSubMenu {
  top: -25px !important;
  transform: translate(5px, -50%) !important;
  &:before {
    transform: translate(-50%, 0) !important;
    top: 100% !important;
    margin-top: -1px;
    left: 50% !important;
    border-width: 6px 6px 0px 6px;
    border-color: #242424 transparent transparent transparent;
  }
}

.NavigationItemBottom {
  margin-top: auto;
}

svg {
  fill: #17d1af6c;
  transition: all 0.15s;
  &:hover {
    fill: #17d1af;
  }
}

.NavigationItemSubMenu {
  position: absolute;
  left: 100%;
  min-height: 100%;
  min-width: 150px;
  background: #3b3b3b;
  top: 0;
  transform: translate(-15%, 0%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s;
}

.NavigationItem:hover
.NavigationItemSubMenu {
  opacity: 1;
  visibility: visible;
  transform: translate(0%, 0%);
}

.NavigationItemActive {
  background: #4f4f4f;
}

.NavigationItemIcon {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  padding: 0 20px;
  align-items: center;
  color: #b0b0b0;
  text-decoration: none !important;
}

.NavigationItemIconLabel {
  font-weight: 400;
  font-size: 13px;
}

