.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 100%;
  overflow-y: scroll;
  position: relative;
  flex: 1;
  width: 100%;
}
.contentWrapper {
  overflow-y: hidden;
  position: relative;
  flex: 1;
  width: 1000px;
  max-width: 100%;
  margin: auto;
}
.innerContent {
  width: 90%;
  margin: auto;
}
.disabledMessage {
  background-color: orange;
  font-size: 14px;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 40px;
  font-weight: bolder;
  line-height: 25px;
}
