@import 'src/assets/styles/colors';

.TextFieldInput {
  border: none;
    padding: 15px 15px;
    font-size: 14px;
    width: 100%;
    outline: none;
    color: #7783a1;
    border-radius: inherit;
}

.TextFieldInputSmall {
  font-size: 12px;
  padding: 6px 8px;
}

.TextFieldInputDisabled {
  background: rgba(100, 100, 100, 0.07);
  cursor: not-allowed;
}

.TextFieldInputError {
  border: 1px solid $color-red !important;
}

.TextFieldInput::placeholder {
  color: #9c9c9c;
}

.TextFieldInputIcon {
  padding-right: 0;
}

.TextFieldCharacters {
  font-size: 12px;
  color: grey;
  white-space: nowrap;
  padding: 0 10px;
}

.TextFieldCharactersError {
  color: $color-red;
}