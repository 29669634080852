@import 'src/assets/styles/colors';

.wrapper {
  width: 60px;
  display: inline-block;
  position: relative;
}
.field {
  max-width: 100%;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 2px;
  color: grey;
  border: 1px solid #e1e1e1;
}
.error {
  color: $color-red;
  font-size: 11px;
  margin-top: -1px;
  position: absolute;
  left: 0;
  top: 105%;
}
