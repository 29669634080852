.ListHeading {
  padding: 30px 60px;
  border-bottom: 1px solid #f1f4f8;
  display: flex;
  align-items: center;
  position: sticky;
  background: white;
  top: 50px;
  z-index: 2;
}

.ListHeadingContent {
  padding: 0 30px;
}

.ListHeadingRight {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.ListHeading h1 {
  font-size: 34px;
  color: #35404a;
  margin: 0;
  padding: 0;
  font-weight: 700;
}

.ListHeading h3 {
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
}
