.BulkEditModalButtonWrapper {
    justify-content: flex-end;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10%;
}

.BulkEditModalTextArea {
    width: 100%; 
    height: 50%; 
    resize: none;
    background: transparent;
    border-radius: 4px;
    font-size: 14px;
    color: rgb(119, 131, 161);
    padding: 15px;
    border: 1px solid #dae1ed;
    font-family: inherit;
}