.bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.fill {
  display: block;
  height: 8px;
  background-color: #659cef;
  border-radius: 3px;
  transition: width 500ms ease-in-out;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
