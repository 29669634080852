@import 'src/assets/styles/colors';
.Table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed !important;
}

.TableWrapper {
  position: relative;
}

.TableWrapperLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  visibility: hidden;
}

.TableWrapperLoaderActive {
  visibility: visible;
  opacity: 1;
}

.TableFilters {
  margin-bottom: 15px;
  margin-top: -20px;
}

.Td {
  text-align: left;
  font-size: 13px;
  padding: 25px 5px;
  color: #35404a;
  overflow: hidden !important;
}

.TrClicable {
  cursor: pointer;
}

.Tr .Td:first-child,
.Tr .Th:first-child {
  padding-left: 7px;
}
.Tr .Td:last-child,
.Tr .Th:last-child {
  padding-right: 7px;
}

.Thead .Th {
  border-top: 1px solid #f1f4f8;
  padding: 13px 0;
  border-bottom: 1px solid #f1f4f8;
}

.Tbody .Tr .Td {
  border-bottom: 1px solid #f1f4f8;
}

.Tbody .Tr:hover .Td {
  background: rgba(#f1f4f8, 0.5);
}

.Tr .BodyResizer {
  background:none;
}

.Tr:hover .BodyResizer {
  background: rgba(#f1f4f8, 0.5); 
}

.Th {
  text-align: left;
  text-transform: uppercase;
  min-width: 70px;
  font-size: 12px;
  color: #7783a1;
  font-weight: 600;
  fill: #7783a1 !important;
  overflow: hidden !important;
}

.ThSort {
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.ThSortIcon {
  width: 10px;
  height: 10px;
}

.ThSortIconHidden{
  display: none;
}

.ThSortIconRotate {
  transform: rotate(180deg);
}

.ThSortable {
  cursor: pointer;
}

.ThActive {
  color: $color-primary2;
  svg * {
    fill: $color-primary2 !important;
  }
}

.Thead:hover .HeadResizer {
  background-color: rgba(242, 242, 242, 1) !important;
  opacity: 0.3 !important;
}

.BodyResizer {
  border-bottom: 1px solid #f1f4f8;
  border-top: 1px solid #f1f4f8;
  width: 1px !important;
}

.HeadResizer {
  width: 1px !important;
  border-top: 1px solid #f1f4f8 !important;
  border-bottom: 1px solid #f1f4f8 !important;
}

.ThShort {
  width: 9rem !important;
}